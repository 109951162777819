<template>
  <div id="dash-main" class="dash-main">
    <DashNavigation />
    <div id="dash-body" class="dash-body">
      <v-idle
        :duration="300"
        @idle="inactive = true"
        style="visibility: hidden"
      />
      <div v-if="inactive">
        <InactiveModal :is-open="inactive" @closeDialog="inactive = false" />
      </div>
      <div id="container" class="container">
        <LowBalanceBanner
          v-if="showLowBalanceAlert && !userDismissedBanner"
          @show-wallet-topup-modal="onShowWalletTopupModal"
        />
        <template v-for="address in addressesPendingReview" :key="address.id">
          <ReviewWalletBanner
            v-if="seeReviewBanner && !reviewBannersDismissed[address.address]"
            :address="address.address"
            @show-wallet-review-modal="onShowWalletReviewModal(address)"
          />
        </template>
        <router-view />
        <WalletTopUpModal
          :show-dialog="showWalletTopupModal"
          @hide-dialog="showWalletTopupModal = false"
        />
        <ReviewWallet
          :show-dialog="showWalletReviewModal"
          :wallet="currentReviewAddress!"
          @close-dialog="showWalletReviewModal = false"
        />
      </div>
    </div>
    <Snackbar
      v-if="toast?.show"
      :text="toast?.message!"
      :button-text="toast?.buttonText"
      :position="toast?.position"
      :duration="toast?.duration"
      :on-dismiss="toast?.onDismiss"
      :on-click="toast?.onClick"
      :on-button-click="toast?.onButtonClick"
      @dismissed="uiStore.hideToast()"
    />
    <InfoSnackbar
      v-if="infoToast?.show"
      :text="infoToast?.message!"
      :header="infoToast?.header!"
      :button-text="infoToast?.buttonText"
      :position="infoToast?.position"
      :duration="infoToast?.duration"
      :on-dismiss="infoToast?.onDismiss"
      :on-click="infoToast?.onClick"
      :on-button-click="infoToast?.onButtonClick"
      @dismissed="uiStore.hideInfoToast()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import DashNavigation from '@/components/DashNavigation.vue';
import LowBalanceBanner from '@/components/dashboard/banners/LowBalanceBanner.vue';
import ReviewWalletBanner from '@/components/dashboard/banners/ReviewWalletBanner.vue';
import { Snackbar, InfoSnackbar } from '@yellowcard/components-library-vue3';
import { useUiStore } from '@/store/modules/ui';
import { useWalletsStore } from '@/store/modules/wallets';
import { storeToRefs } from 'pinia';
import InactiveModal from '@/components/auth/InactiveModal.vue';
import { usePartnerStore } from '@/store/modules/partner';
import WalletTopUpModal from '@/components/dashboard/modals/WalletTopUpModal.vue';
import ReviewWallet from '@/components/dashboard/modals/ReviewWallet.vue';
import { PortalServiceTypes } from '@/types/portalService';
import { useAuthStore } from '@/store/modules/auth';

export default defineComponent({
  name: 'HomeView',
  components: {
    Snackbar,
    DashNavigation,
    InactiveModal,
    LowBalanceBanner,
    ReviewWalletBanner,
    WalletTopUpModal,
    ReviewWallet,
    InfoSnackbar,
  },
  setup() {
    const searchInputValue = ref('');
    const inactive = ref(false);

    const partnerStore = usePartnerStore();
    const {
      partner,
      showLowBalanceAlert,
      userDismissedBanner,
      reviewBannersDismissed,
    } = storeToRefs(partnerStore);

    const authStore = useAuthStore();
    const walletsStore = useWalletsStore();
    const { user } = storeToRefs(authStore);
    const isAdmin = user.value?.role === PortalServiceTypes.Role.ADMIN;
    const isTreasury = user.value?.role === PortalServiceTypes.Role.TREASURY;
    const seeReviewBanner = isAdmin || isTreasury;

    const showWalletTopupModal = ref(false);
    const showWalletReviewModal = ref(false);
    const walletAddress = ref(partner.value?.walletAddress || '');

    const uiStore = useUiStore();
    const { toast, infoToast } = storeToRefs(uiStore);
    const { addressesPendingReview, settlementWalletAddresses } =
      storeToRefs(walletsStore);
    const currentReviewAddress = ref<PortalServiceTypes.Address>();

    const onShowWalletTopupModal = () => {
      showWalletTopupModal.value = true;
    };

    const onShowWalletReviewModal = (address: PortalServiceTypes.Address) => {
      showWalletReviewModal.value = true;
      currentReviewAddress.value = address;
    };

    return {
      toast,
      searchInputValue,
      uiStore,
      inactive,
      showLowBalanceAlert,
      onShowWalletTopupModal,
      onShowWalletReviewModal,
      showWalletTopupModal,
      showWalletReviewModal,
      walletAddress,
      userDismissedBanner,
      reviewBannersDismissed,
      infoToast,
      addressesPendingReview,
      currentReviewAddress,
      seeReviewBanner,
      settlementWalletAddresses,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/common/styles/_base.scss';
@import '@yellowcard/components-library-vue3/lib/index.css';

html {
  font-family: 'Jost', sans-serif;
}
.dash-main {
  display: flex;
  @include media('<tablet') {
    display: block;
    width: 100%;
  }
}

.dash-body {
  height: 100vh;
  background-color: $brand-grey-50;
  max-width: 80%;
  min-width: 83%;
  @include media('<tablet') {
    background-color: #fff;
    min-width: 90%;
    max-width: 200%;
    overflow-x: scroll;
  }
}

.app-container {
  @include media('<tablet') {
    background-size: contain !important;
  }
}

.dash-header {
  background-color: #fff !important;
  padding: 16px;
  @include media('<tablet') {
    display: none;
  }
}

.container {
  height: max-content;

  > .div-banner-body:not(:first-child) {
    margin-top: 10px;
  }

  @include media('<tablet') {
  }
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-container {
  width: 40%;
}

.right-top {
  cursor: pointer;
}
</style>
