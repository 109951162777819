import moment from 'moment';

export const formatDate = (d: string, format = 'LLL') =>
  moment(d).format(format);

export const numberFormat = (
  number: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 0,
) => {
  if (!number) {
    return '0.00';
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(number);
};

export const titleCase = (str: string) => {
  if (!str) return str;
  const words = str.toLowerCase().split(' ');
  return words
    .map(word => word[0].toUpperCase().concat(word.slice(1)))
    .join(' ');
};

export const unitTest = (testNumber: number) => testNumber + 1;

const CURRENCY_NETWORK_KEY = 'YC-CURRENCY-NETWORK';

export function setCurrencyNetwork(value: string) {
  console.log(value);
  localStorage.setItem(CURRENCY_NETWORK_KEY, value);
}

export function getCurrencyNetwork() {
  return localStorage.getItem(CURRENCY_NETWORK_KEY) || 'usdt-trc20';
}

export const supportedNetworks: string[] = [
  'ERC20',
  'TRC20',
  'POL',
  'BTC',
  'XLM',
  'CELO',
  'BASE',
];

export const supportedCoins: string[] = ['USDC', 'USDT', 'BTC', 'PYUSD'];

export const currencyNetworkMap: Record<
  any,
  { name: string; network: string; icon: string; label: string }
> = {
  'usdt-erc20': {
    name: 'Tether (USDT) ERC-20',
    network: 'Ethereum (ERC20)',
    icon: 'USDT',
    label: 'USDT (ERC-20)',
  },
  'usdt-trc20': {
    name: 'Tether (USDT) TRC-20',
    network: 'TRON (TRC-20)',
    icon: 'USDT',
    label: 'USDT (TRC-20)',
  },
  'usdc-erc20': {
    name: 'USDC (ERC-20)',
    network: 'Ethereum (ERC20)',
    icon: 'USDC',
    label: 'USDC (ERC-20)',
  },
  'usdc-pol': {
    name: 'USDC (POL)',
    network: 'Polygon (POL)',
    icon: 'USDC',
    label: 'USDC (POL)',
  },
  'btc-btc': {
    name: 'Bitcoin (BTC)',
    network: 'Bitcoin (BTC)',
    icon: 'BTC',
    label: 'BTC (BTC)',
  },
  'usdc-xlm': {
    name: 'USDC (XLM)',
    network: 'Stellar (XLM)',
    icon: 'XLM',
    label: 'USDC (XLM)',
  },
  'usdt-celo': {
    name: 'USDT (CELO)',
    network: 'CELO',
    icon: 'USDT',
    label: 'USDT (CELO)',
  },
  'usdt-pol': {
    name: 'USDT (POL)',
    network: 'Polygon (POL)',
    icon: 'USDT',
    label: 'USDT (POL)',
  },
  'usdt-sol': {
    name: 'USDT (SOL)',
    network: 'Solana (SOL)',
    icon: 'USDT',
    label: 'USDT (SOL)',
  },
  'usdc-sol': {
    name: 'USDC (SOL)',
    network: 'Solana (SOL)',
    icon: 'USDC',
    label: 'USDC (SOL)',
  },
  'usdc-base': {
    name: 'USDC (BASE)',
    network: 'BASE',
    icon: 'BASE',
    label: 'USDC (BASE)',
  },
  'pyusd-erc20': {
    name: 'PYUSD (ERC20)',
    network: 'Ethereum (ERC20)',
    icon: 'PYUSD',
    label: 'PYUSD (ERC20)',
  },
};

export function mapChannelType(channelType: string) {
  switch (channelType) {
    case 'bank':
    case 'eft':
    case 'p2p':
      return 'bank';
    case 'momo':
      return 'momo';
    default:
      return channelType;
  }
}

export function getChannelTypeFullName(channelType: string) {
  const type = mapChannelType(channelType);
  switch (type) {
    case 'bank':
      return 'Bank Transfer';
    case 'momo':
      return 'Mobile Money';
    default:
      return type;
  }
}

export function mapCountryCode(countryCode: string) {
  const countries = {
    CM: 'Cameroon',
    ZA: 'South Africa',
    UG: 'Uganda',
    ZM: 'Zambia',
    GA: 'Gabon',
    NG: 'Nigeria',
    RW: 'Rwanda',
    TZ: 'Tanzania',
    KE: 'Kenya',
    MW: 'Malawi',
    TG: 'Togo',
    BW: 'Botswana',
    CI: 'Ivory Coast',
    SN: 'Senegal',
    GH: 'Ghana',
    BF: 'Burkina Faso',
    ML: 'Mali',
    BJ: 'Benin Republic',
    CG: 'Congo',
    US: 'United States',
  };

  return countries[countryCode as keyof typeof countries] || countryCode;
}

export default {};
