<template>
  <!-- desktop nav -->
  <div id="dash-navigation" class="dash-navigation">
    <div class="logo-dash-main">
      <router-link to="/">
        <img
          :src="require('@/assets/Logo_secondary_for_yellow_bg.svg')"
          alt="YellowCard Logo"
        />
      </router-link>
    </div>
    <div class="divider"></div>
    <div class="partner-text">
      <div class="partner-text__partner">{{ partner?.name }}</div>
      <div class="partner-text__id">ID {{ user?.partnerId }}</div>
    </div>
    <div class="divider"></div>
    <div v-for="(linkParent, index) in Object.keys(navigations)" :key="index">
      <div class="nav-headers">{{ linkParent }}</div>
      <div
        v-for="(nav, navIndex) in navigations[linkParent]"
        :key="navIndex"
        class="nav-item"
        :class="isRoutePath($route.path, nav.link) ? 'active' : ''"
        @click="go(nav.link)"
        @keypress="go(nav.link)"
      >
        <img
          :src="require('@/assets/navigation/' + nav.icon + '.svg')"
          :alt="nav.label"
        />&nbsp;
        {{ nav.label }}
      </div>
    </div>
  </div>

  <!-- mobile nav -->
  <div id="dash-navigation-mobile" class="dash-navigation-mobile">
    <div class="dash-items-mobile">
      <div>
        <router-link to="/">
          <img
            :src="require('@/assets/Logo_secondary_for_yellow_bg.svg')"
            alt="YellowCard Logo"
          />
        </router-link>
      </div>
      <div @click.prevent="toggleMobileMenu()" @keypress="toggleMobileMenu()">
        <img
          :src="require('@/assets/navigation/hamburger.svg')"
          alt="hamburger"
        />
      </div>
    </div>
  </div>

  <!-- mobile nav wrap -->
  <div
    v-if="showDropDown"
    id="dash-navigation-mobile-wrap"
    class="dash-navigation-mobile-wrap"
  >
    <div class="dash-items-mobile-wrap">
      <div>
        <router-link to="/" class="logo">
          <img
            :src="require('@/assets/device_secondary_for_yellow_bg.svg')"
            alt="YellowCard Logo"
            class="dash-navigation-mobile-wrap_device_secondary_for_yellow_bg"
          />
        </router-link>
      </div>
      <div @click.prevent="toggleMobileMenu()" @keypress="toggleMobileMenu()">
        <img
          :src="require('@/assets/navigation/menu_close.svg')"
          alt="menu close"
          class="menu_close"
        />
      </div>
    </div>
    <div>
      <!--      <div-->
      <!--        class="nav-item"-->
      <!--        :class="isRoutePath($route.path, '/activities') ? 'active' : ''"-->
      <!--        @click="go('/activities')"-->
      <!--        @keypress="go('/activities')"-->
      <!--      >-->
      <!--        Activities-->
      <!--      </div>-->
      <div v-for="(linkParent, index) in Object.keys(navigations)" :key="index">
        <div
          class="items-mobile-wrap-text"
          @click.prevent="toggleMobileMenuAccordion(linkParent)"
          @keypress="toggleMobileMenuAccordion(linkParent)"
        >
          <div>{{ linkParent }}</div>
          <div>
            <img
              :src="require('@/assets/navigation/chevron_down.svg')"
              alt="chevron down"
              class="chevron_down"
            />
          </div>
        </div>
        <div
          :class="{
            'display-hidden': showDropDownMenu !== linkParent,
          }"
        >
          <div
            v-for="(nav, navIndex) in navigations[linkParent]"
            :key="navIndex"
            class="nav-item"
            :class="{
              active: isRoutePath($route.path, nav.link),
            }"
            @click="go(nav.link)"
            @keypress="go(nav.link)"
          >
            {{ nav.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="logout-buttons">
      <Button text="Log out" variant="outline" @buttonClicked="logOut" />
      <!--      <Button text="Sign up" />-->
    </div>
  </div>
</template>

<script lang="ts">
import router from '@/router';
import { computed, defineComponent, ref } from 'vue';
import { Button } from '@yellowcard/components-library-vue3';
import { useAuthStore } from '@/store/modules/auth';
import { storeToRefs } from 'pinia';
import { usePartnerStore } from '@/store/modules/partner';
import { logoutUser } from '@/utils/logout';

export default defineComponent({
  name: 'DashNavigation',
  components: {
    Button,
  },
  setup() {
    const authStore = useAuthStore();
    const { user } = storeToRefs(authStore);
    const partnerStore = usePartnerStore();
    const {
      partner,
      walletManagementEnabled,
      apiKeysManagementEnabled,
      channelsCoverageEnabled,
    } = storeToRefs(partnerStore);

    const logOut = async () => {
      logoutUser();
      await router.push('login');
      sessionStorage.clear();
    };

    const go = (link: string) => {
      if (link === '/logout') {
        logOut();
      } else {
        router.push(link);
      }
    };

    const navigations = computed(() => {
      const baseNavigations: Record<string, Record<string, string>[]> = {
        'your business': [
          {
            icon: 'transactions',
            label: 'Transactions',
            link: '/transactions',
          },
          { icon: 'balance', label: 'Balance', link: '/balance' },
        ],
        // 'developers tools': [
        //   // { icon: 'webhooks', label: 'Webhooks', link: '/webhooks' },
        // ],
      };

      if (channelsCoverageEnabled.value) {
        baseNavigations['your business'].push({
          icon: 'coverage',
          label: 'Coverage',
          link: '/coverage',
        });
      }

      if (apiKeysManagementEnabled.value) {
        baseNavigations['developers tools'] = [
          { icon: 'keys', label: 'API Keys', link: '/api-keys' },
        ];
      }

      baseNavigations['your account'] = [];
      if (walletManagementEnabled.value) {
        baseNavigations['your account'].push({
          icon: 'wallet',
          label: 'Wallet Management',
          link: '/wallets',
        });
      }

      baseNavigations['your account'].push(
        { icon: 'settings', label: 'Settings', link: '/settings' },
        {
          icon: 'logout',
          label: 'Log out',
          link: '/logout',
        },
        // { icon: 'legal', label: 'Legal', link: '/legal' },
      );

      return baseNavigations;
    });

    const showDropDown = ref(false);
    const showDropDownMenu = ref('');

    const isRoutePath = (route: string, path: string) => {
      if (route === '/' && path === '/') {
        return true;
      }
      if (path === '/') {
        return false;
      }
      if (route.startsWith(path)) {
        return true;
      }
      return false;
    };

    const toggleMobileMenu = () => {
      showDropDown.value = !showDropDown.value;
      showDropDownMenu.value = '';
    };

    const toggleMobileMenuAccordion = (key: string) => {
      if (showDropDownMenu.value === key) {
        showDropDownMenu.value = '';
      } else {
        showDropDownMenu.value = key;
      }
    };

    return {
      go,
      isRoutePath,
      toggleMobileMenu,
      toggleMobileMenuAccordion,
      navigations,
      showDropDown,
      showDropDownMenu,
      user,
      logOut,
      partner,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/common/styles/_base.scss';

.dash-navigation {
  max-width: 18%;
  min-width: 17%;
  border-right: 1px solid $brand-grey-300;
  height: 100vh;
  background-color: #fff !important;
  @include media('<tablet') {
    display: none;
  }
}

.partner-text {
  margin: 2rem 24px;

  &__partner {
    font-weight: 500;
  }

  &__id {
    color: $brand-grey-500;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.nav-item {
  color: $brand-grey-500;
  display: flex;
  transition: all;
  padding: 0.7rem 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover,
  &.active {
    color: $brand-secondary-purple;
    background-color: $brand-secondary-purple-25;
  }

  > img {
    filter: grayscale(100);
    opacity: 0.5;
  }
}

.nav-headers {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: 500;
}

.divider {
  border-bottom: 1px solid $brand-grey-300;
  margin-left: 24px;
  margin-right: 24px;
}

.dash-navigation-mobile {
  display: none;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  @include media('<tablet') {
    display: block;
    right: 0;
    z-index: 9999;
  }
}

.dash-items-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background-color: #fff !important;
}

.dash-items-mobile-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.dash-navigation-mobile-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  z-index: 100;

  &_device_secondary_for_yellow_bg {
    height: 44px;
  }

  img.menu_close {
    height: 14px;
    padding-right: 5px;
  }
}

.items-mobile-wrap-text {
  padding: 16px 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-dash-main {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 3rem;
}

.logout-buttons {
  padding: 24px;
  //Lines commented out for mvp, we do not have signup functionality
  //display: grid;
  //grid-template-columns: repeat(2, minmax(0, 1fr));
  //gap: 16px;
}
</style>
