import axios, { AxiosInstance } from 'axios';
import { PortalServiceTypes } from '@/types/portalService';
import { TeamMemberTypes } from '@/types/tables/team';
import { AccountsTypes } from '@/types/accounts';
import { logoutUser } from '@/utils/logout';
import router from '@/router';
import { getDeviceId, getEncryptionKey } from '@/utils/csrf';
import { useAuthStore } from '@/store/modules/auth';

const url = process.env.VUE_APP_PARTNER_API;

async function http(token?: string): Promise<AxiosInstance> {
  const encryptionKey = await getEncryptionKey();
  const deviceid = await getDeviceId();
  const params = {
    baseURL: url,
    headers: {
      'Content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: token,
      'x-csrf-token': encryptionKey,
      deviceid,
    },
  };
  if (!token) {
    delete params.headers.Authorization;
  }
  const instance = axios.create(params);
  instance.interceptors.response.use(
    response => response,
    async error => {
      console.log(error);
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 401 && error.response.data.code !== 'InvalidLogin') {
          logoutUser();
          await router.push('login');
          sessionStorage.clear();
          throw new Error(error.response.data.message);
        } else {
          throw new Error(error.response?.data.message);
        }
      } else {
        try {
          const auth = useAuthStore();
          await auth.CheckAccessToken();
          error.config.headers.Authorization = auth.user?.token;
          return axios(error.config);
        } catch (e) {
          logoutUser();
          await router.push('login');
          sessionStorage.clear();
          throw new Error('something went wrong');
        }
      }
    },
  );

  return instance;
}

const PortalService = {
  async Login(
    payload: PortalServiceTypes.LoginPayload,
  ): Promise<PortalServiceTypes.LoginResponse> {
    const api = await http();
    const res = await api.post('users/login', payload);
    return res?.data;
  },

  async SetPassword(
    payload: PortalServiceTypes.SetPasswordPayload,
    token?: string,
  ): Promise<void> {
    const api = await http(token);
    await api.put('users/password', payload);
  },

  async VerifySoftwareToken(
    payload: PortalServiceTypes.VerifySoftwareTokenPayload,
  ): Promise<PortalServiceTypes.VerifySoftwareTokenResponse> {
    const api = await http();
    const res = await api.post('users/verify-token', payload);
    return res?.data;
  },

  async FetchApiKeys(token: string): Promise<PortalServiceTypes.ApiKey[]> {
    const api = await http(token);
    const res = await api.get('partner/apikey');
    return res?.data;
  },

  async UpdateApiKey(
    token: string,
    apiKey: PortalServiceTypes.ApiKey,
  ): Promise<PortalServiceTypes.ApiKey> {
    const api = await http(token);
    const res = await api.put('partner/apikey', apiKey);
    return res?.data;
  },

  async RespondToAuthChallenge(
    payload: PortalServiceTypes.RespondToAuthChallengePayload,
  ): Promise<PortalServiceTypes.RespondToAuthChallengeResponse> {
    const api = await http();
    const res = await api.post('users/challenge-response', payload);
    return res?.data;
  },

  async GetAccount(token: string): Promise<AccountsTypes.Account> {
    const api = await http(token);
    const res = await api.get('partner/accounts');
    return res?.data;
  },

  async RefreshAccessToken(
    payload: PortalServiceTypes.RefreshAccessTokenPayload,
  ): Promise<PortalServiceTypes.RefreshAccessTokenResponse> {
    const api = await http();
    const res = await api.post('users/refresh', payload);
    return res?.data;
  },

  async GetTeamMembers(
    token: string,
  ): Promise<TeamMemberTypes.GetTeamMembersResponse> {
    const api = await http(token);
    const res = await api.get('partner/users');
    return res?.data;
  },

  async InviteTeamMembers(
    token: string,
    payload: TeamMemberTypes.InviteMemberPayload,
  ) {
    const api = await http(token);
    const res = await api.post('users/add', payload);
    return res?.data;
  },

  async CreateWalletAddress(
    token: string,
    payload: PortalServiceTypes.CreateWalletRequest,
  ): Promise<PortalServiceTypes.PartnerWallet> {
    const api = await http(token);
    const res = await api.post('partner/wallet', payload);
    return res?.data;
  },

  async VerifyOtpCode(
    token: string,
    otp: string,
  ): Promise<PortalServiceTypes.VerifyOtpResponse> {
    const api = await http(token);
    const res = await api.get(`user/otp/${otp}`);
    return res?.data;
  },

  async SendOtp(token: string): Promise<void> {
    const api = await http(token);
    await api.put('user/otp');
  },

  async DeleteWalletAddress(
    token: string,
    address: string,
    payload: PortalServiceTypes.DeleteWalletRequest,
  ): Promise<PortalServiceTypes.DeleteWalletResponse> {
    const api = await http(token);
    const res = await api.delete(`partner/wallet/${address}`, {
      data: payload,
    });
    return res?.data;
  },

  async SetPartnerLowBalanceLimit(
    token: string,
    payload: TeamMemberTypes.UpdatePartnerLowBalanceRequest,
  ): Promise<PortalServiceTypes.UpdateLowLimitResponse> {
    const api = await http(token);
    const res = await api.post('partner/low-balance-limit', payload);
    return res?.data;
  },

  async getAutomatedPayoutConfig(
    token: string,
  ): Promise<PortalServiceTypes.GetPayoutConfigResponse> {
    const api = await http(token);
    const res = await api.get('partner/payout-config');
    return res?.data;
  },

  async setAutomatedPayoutConfig(
    token: string,
    payload: PortalServiceTypes.PayoutConfigRequest,
  ): Promise<PortalServiceTypes.UpdatePayoutConfigResponse> {
    const api = await http(token);
    const res = await api.post('partner/payout-config', payload);
    return res?.data;
  },

  async GetProfile(
    token: string,
  ): Promise<PortalServiceTypes.GetProfileResponse> {
    const api = await http(token);
    const res = await api.get('partner/profile');
    return res?.data;
  },

  async AssignDefaultPartnerWalletAddress(
    token: string,
  ): Promise<PortalServiceTypes.AssignPartnerWalletAddressResponse> {
    const api = await http(token);
    const res = await api.get('partner/address');
    return res?.data;
  },

  async GetWalletAddresses(
    token: string,
  ): Promise<PortalServiceTypes.GetWalletAddressesResponse> {
    const api = await http(token);
    const res = await api.get('partner/addresses');
    return res?.data;
  },

  async GetSettlementWallets(
    token: string,
  ): Promise<PortalServiceTypes.GetSettlementWalletsResponse> {
    const api = await http(token);
    const res = await api.get('partner/wallets');
    return res?.data;
  },

  async UpdateWalletStatus(
    token: string,
    payload: PortalServiceTypes.UpdateWalletStatusRequest,
  ): Promise<PortalServiceTypes.UpdateWalletStatusResponse> {
    const api = await http(token);
    const res = await api.put('partner/wallet', payload);
    return res?.data;
  },

  async ProcessSettlement(
    payload: PortalServiceTypes.ProcessSettlementPayload,
    token: string,
  ): Promise<void> {
    const api = await http(token);
    const res = await api.post('partner/settlement/withdrawal', payload);
    return res?.data;
  },

  async GetAllData(
    token: string,
    payload: PortalServiceTypes.GetAllDataPayload,
  ): Promise<PortalServiceTypes.GetAllDataResponse> {
    const api = await http(token);
    const res = await api.post('data', payload);
    return res?.data;
  },

  async GetData(
    token: string,
    payload: PortalServiceTypes.GetDataPayload,
  ): Promise<PortalServiceTypes.GetDataResponse> {
    const api = await http(token);
    const res = await api.post('transactions', payload);
    return res?.data;
  },

  async CreateAPIKey(
    token: string,
    payload: PortalServiceTypes.CreateAPIKey,
  ): Promise<PortalServiceTypes.CreateAPIKeyResponse> {
    const api = await http(token);
    const res = await api.post('partner/apikey', payload);
    return res?.data;
  },

  async DeleteAPIKey(token: string, apiKey: string): Promise<void> {
    const api = await http(token);
    return api.delete(`partner/apikey/${apiKey}`);
  },

  async UpdateUserRole(
    token: string,
    payload: PortalServiceTypes.UpdateUserRolePayload,
  ): Promise<PortalServiceTypes.UpdateUserRoleResponse> {
    const api = await http(token);
    const res = await api.post('users/update', payload);
    return res?.data;
  },

  async ResetUserPassword(
    token: string,
    payload: PortalServiceTypes.ResetUserPasswordPayload,
  ): Promise<void> {
    const api = await http(token);
    await api.post('user/reset-password', payload);
  },

  async ResendConfirmationCode(token: string, userId: string): Promise<void> {
    const api = await http(token);
    await api.put(`users/resend-code/${userId}`);
  },

  async DeleteUser(token: string, userId: string): Promise<void> {
    const api = await http(token);
    await api.put(`users/delete/${userId}`);
  },

  async ConfirmPasswordReset(
    payload: PortalServiceTypes.ConfirmPasswordResetPayload,
  ): Promise<void> {
    const api = await http();
    await api.post('users/confirm-password-reset', payload);
  },

  async GetChannels(
    token: string,
  ): Promise<PortalServiceTypes.GetChannelsResponse> {
    const api = await http(token);
    const res = await api.get('channels');
    return res?.data;
  },
};

export default PortalService;
